body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0e1219;
  color: #dfe9e8;
  overflow: hidden;
  user-select: none; /* Likely future */
  height: 1080px;
}
/* ::selection {
  color: none;
  background: none;
} */
